<template>
<div class='uamEvent'>
	<div class='ref'>{{item.id}}</div>
	<div class='user'><div>{{item.user}}<div>{{item.userEmail}}</div></div></div>
	<div class='action'><div class='miniButton' :class='actionColour'>{{$t(`views.uamManager.events.${item.action}`)}}</div></div>
	<div class='object'><div>{{$t(`views.uamManager.models.${item.object}`)}} Id: {{item.objectId}}<div v-if='item.historyId || item.scoreId'>
		<template v-if='item.historyId'>History Id: {{item.historyId}}</template>
		<template v-else>Score Id: {{item.scoreId}}</template>
	</div></div><div v-if='item.more' class='miniButton neutral' @click='showMore'>...</div></div>
	<div class='client'><div>{{item.client}}<div>{{item.clientEmail}}</div></div></div>
	<div class='created'>{{created}}</div>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import AlertModal from '@/components/common/modals/Alert'

export default {
	name: 'UamEvent',
	mixins: [dateTime, smallModalMixin],
	props: ['item'],
	computed: {
		actionColour() {
			return 	['viewed'].includes(this.item.action) ? 'primary' :
					['archived','unarchived','enabled','disabled'].includes(this.item.action) ? 'success' :
					'accent'
		},
		created() { 
			this.langBackdoor
			return this.dateTime(this.item.created) 
		}
	},
	methods: {
		showMore() {
			this.showSmallModal(AlertModal, {
                title: this.$t('views.uamManager.addendum'),
                message: `<pre style='white-space: pre-wrap;'>${JSON.stringify(this.item.more, null, 4)}</pre>`
            })
		}
	}
}
</script>

<style lang='scss'>
.uamEvent {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin: 0 $size-gutter * 2;

	color: $color-black;
	&:hover { background: $color-neutral-silver; }

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		&:not(.delete) {
			cursor: pointer;
		}

		> div > div { font-size: $size-font-small; color: $color-primary-accent; }
	}

	.action, .ref, .created {
		flex: .6;
	}

	.object {
		flex: 1;
		.miniButton { margin-left: $size-gutter * 2; }
	}

	.user, .client {
		flex: 1.2;
	}

	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }


}
</style>