import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/'
	}
}

export const uamApi = buildApi('uam', routes, '/manager/uam')

