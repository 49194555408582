<template>
<div class='uamManager'>
	<div class='headerPanel'><div>
		<SearchSelectInput 
			:options='vSelectStaff' 
			placeholder='Filter by user...'
			@search='searchStaff'
			v-model='selectedStaff'
		/>
	</div><div>
		<SearchSelectInput 
			:options='vSelectEvents' 
			:placeholder='$t("views.uamManager.phFilterEvents")'
			v-model='selectedEvent'
		/>
	</div><div>
		<SearchSelectInput 
			:options='vSelectModels' 
			:placeholder='$t("views.uamManager.phFilterModels")'
			v-model='selectedModel'
		/>
	</div></div>
	<div class='contentPanel'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			class='uamEvents recycleScroller'
		>
			<template slot='sticky'>
				<TableColumns v-if='items.length'
					:columns='[{
						label: $t("elements.cols.ref"),
						flex: .6
					},{
						label: "User",
						flex: 1.2
					},{
						label: $t("elements.cols.action"),
						flex: .6
					},{
						label: $t("elements.cols.object"),
						flex: 1
					},{
						label: $t("elements.cols.client"),
						flex: 1.2
					},{
						label: $t("elements.cols.created"),
						flex: .6
					},]'
				/>
			</template>
			<template v-slot='{ item }'>
				<UamEvent :item='item' :itemKey='item.ref' />
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
		<transition name='fade'><Loading v-show='loading' /></transition>		
	</div>
</div>
</template>

<script>
import { debounce, uniq, isEqual } from 'lodash'
import { staffApi } from '@/services/api/modules/manager/staff'
import { uamApi } from '@/services/api/modules/manager/uam'
import { smallModalMixin } from '@/components/common/mixins/modal'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import AtBottom from '@/components/common/AtBottom'
import TableColumns from '@/components/common/TableColumns'
import SearchSelectInput from '@/components/common/SearchSelectInput'
import Loading from '@/components/common/Loading'
import UamEvent from './UamEvent'

export default {
	name: 'UamManager',
	mixins: [smallModalMixin],
	components: { Loading, SearchSelectInput, RecycleScroller, AtBottom, TableColumns, UamEvent },
	data() { return {
		events: {
			client: ['viewed','created','updated','archived','unarchived'],
			clientSet: ['viewed','created','updated','emailed','enabled','disabled'],
			outcome: ['viewed','created','updated','enabled','disabled']
		},
		staff: [],
		items: [],
		selectedStaff: null,
		selectedModel: null,
		selectedEvent: null,
		loading: false
	}},
	computed: {
		vSelectStaff() {
			return this.staff.map(row => ({
				id: row.id,
				label: `${row.firstname} ${row.lastname} (${row.email})`
			}))
		},
		vSelectModels() {
			const models = this.selectedEvent ? Object.keys(this.events).filter(event => this.events[event].includes(this.selectedEvent.id)) : Object.keys(this.events)
			return models.map(model => ({
				id: model,
				label: this.$t(`views.uamManager.models.${model}`)
			}))
		},
		vSelectEvents() {
			const events = this.selectedModel ? this.events[this.selectedModel.id] : uniq([...this.events.client, ...this.events.clientSet, ...this.events.outcome])
			return events.map(event => ({
				id: event,
				label: this.$t(`views.uamManager.events.${event}`)
			}))
		},
		filter() {
			return {
				staffId: this.selectedStaff ? this.selectedStaff.id : null,
				object: this.selectedModel ? this.selectedModel.id : null,
				action: this.selectedEvent ? this.selectedEvent.id : null,
			}
		}
	},
	methods: {
		searchStaff: debounce(function(search, loading) {
			loading(true)
			this.fetchStaff(search).then(() => loading(false))
		}, 300, { leading: false, trailing: true }),
		fetchStaff(search) {
			return staffApi.touch('fetch', { search }).then(([err, result]) => {
				if (!err) this.staff = result.items
			})
		},
		fetchTop() {
			this.loading = true
			uamApi.touch('fetch', this.filter).then(([err, result]) => {
				if (!err) this.items = result.items
				this.loading = false
			})
		},
		fetchNext() {
			this.loading = true
			uamApi.touch('fetch', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
				if (!err) this.items.push(...result.items)
				this.loading = false
			})			
		},
		atBottom() {}
	},
	watch: {
		filter(a, b) {
			if (!isEqual(a, b)) this.fetchTop()
		}
	},
	mounted() {
		this.fetchTop()
		this.fetchStaff()
	}
}
</script>

<style lang='scss'>
.uamManager {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $background-panel;

	.headerPanel {
		padding: $size-gutter * 2;
		padding-bottom: $size-gutter * 2;
		display: flex;

		> div { 
			flex-basis: 350px;
			margin-right: $size-gutter *2; 
			border: 1px solid $color-neutral-shadow;
		}

	}

	.contentPanel {
		position: relative;

		.resultInfo {
			margin-top: $size-gutter * 2;
		}

		.tableColumns { margin: 0 $size-gutter * 2; }

		.loading { background: rgba($color-neutral-panel, 0.3); }

		.vue-recycle-scroller__stickySlot {
			top: 0;
		}

	}
}
</style>