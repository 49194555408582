var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"uamManager"},[_c('div',{staticClass:"headerPanel"},[_c('div',[_c('SearchSelectInput',{attrs:{"options":_vm.vSelectStaff,"placeholder":"Filter by user..."},on:{"search":_vm.searchStaff},model:{value:(_vm.selectedStaff),callback:function ($$v) {_vm.selectedStaff=$$v},expression:"selectedStaff"}})],1),_c('div',[_c('SearchSelectInput',{attrs:{"options":_vm.vSelectEvents,"placeholder":_vm.$t("views.uamManager.phFilterEvents")},model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}})],1),_c('div',[_c('SearchSelectInput',{attrs:{"options":_vm.vSelectModels,"placeholder":_vm.$t("views.uamManager.phFilterModels")},model:{value:(_vm.selectedModel),callback:function ($$v) {_vm.selectedModel=$$v},expression:"selectedModel"}})],1)]),_c('div',{staticClass:"contentPanel"},[_c('RecycleScroller',{staticClass:"uamEvents recycleScroller",attrs:{"items":_vm.items,"item-size":40,"buffer":500},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('UamEvent',{attrs:{"item":item,"itemKey":item.ref}})]}}])},[_c('template',{slot:"sticky"},[(_vm.items.length)?_c('TableColumns',{attrs:{"columns":[{
						label: _vm.$t("elements.cols.ref"),
						flex: .6
					},{
						label: "User",
						flex: 1.2
					},{
						label: _vm.$t("elements.cols.action"),
						flex: .6
					},{
						label: _vm.$t("elements.cols.object"),
						flex: 1
					},{
						label: _vm.$t("elements.cols.client"),
						flex: 1.2
					},{
						label: _vm.$t("elements.cols.created"),
						flex: .6
					},]}}):_vm._e()],1),_c('template',{slot:"after"},[_c('AtBottom',{attrs:{"action":_vm.fetchNext}})],1)],2),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }